import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Info = () => {
  let location = useLocation();
  const path = location.pathname.slice(1).split('/');
  const [info, setInfo] = useState({});

  const apiUrl =
    'http://45.11.26.17:5000/' + path[1] + '/' + path[2] + '/' + path[3] + '/' + path[4];

  useEffect(() => {
    const loadInfo = () => {
      axios
        .get(apiUrl)
        .then((res) => {
          setInfo(res.data[0]);
        })
        .catch((err) => {
          // Not in the 200 response range
          console.log(err.res.data);
          console.log(err.res.status);
          console.log(err.res.headers);
        });
    };
    loadInfo();
  }, []);

  const handleChange = (event) => {
    console.log(event.target.id);
    setInfo({ um: event.target.value });
  };

  if (info.lbpro_id !== 0) {
    let style = 'info w-screen h-full px-8 leading-loose relative';
    let infoColor =
      'text-info-color font-semibold bg-transparent w-min focus:outline-none focus:text-my-cyan';
    return (
      <div className={style}>
        {' '}
        <p>
          УМ: <input className={infoColor} id="lbro_id" value={info.lbpro_id}></input>
        </p>
        <p>
          IP адрес:{' '}
          <input
            className={infoColor}
            id="ip"
            value={info.ip}
            onChange={() => {
              handleChange();
            }}></input>
        </p>
        <p>
          Маска: <input className={infoColor} id="mask" value={info.mask}></input>
        </p>
        <p>
          Шлюз: <input className={infoColor} id="gateway" value={info.gateway}></input>
        </p>
        <p>
          Громкость микрофона:{' '}
          <input className={infoColor} id="loud_in" value={info.loud_in}></input>
        </p>
        <p>
          Громкость динамика:{' '}
          <input className={infoColor} id="loud_out" value={info.loud_out}></input>
        </p>
      </div>
    );
  } else {
    let date = info.to_date.split('-');
    let style = 'info w-screen h-full px-8 leading-loose relative';
    let infoColor =
      'text-info-color font-semibold bg-transparent focus:outline-none focus:text-my-cyan';
    let showDate = date[2].slice(0, 2) + '.' + date[1] + '.' + date[0] + 'г.';
    return (
      <form className={style}>
        <p>
          УМ:{' '}
          <input
            className={infoColor}
            id="um"
            defaultValue={info.um}
            onChange={handleChange}></input>
        </p>
        <p>
          Серийный номер: <input className={infoColor} value={info.serial}></input>
        </p>
        <p>
          IP адрес: <input className={infoColor} value={info.ip}></input>
        </p>
        <p>
          Маска: <input className={infoColor} value={info.mask}></input>
        </p>
        <p>
          Шлюз: <input className={infoColor} value={info.gateway}></input>
        </p>
        <p>
          Дата ТО: <input className={infoColor} value={showDate}></input>
        </p>
        <p> Комментарий: </p>
        <p>
          <input className={infoColor} value={info.to_comment}></input>
        </p>
      </form>
    );
  }
};

export default Info;
