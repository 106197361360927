import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const apiUrl = 'http://45.11.26.17:5000/disp';

const ListDisps = () => {
  const [loading, setLoading] = useState(false);
  const [disps, setDisps] = useState([]);
  useEffect(() => {
    const loadDisps = () => {
      axios
        .get(apiUrl)
        .then((res) => {
          setDisps(res.data);
        })
        .catch((err) => {
          // Not in the 200 response range
          console.log(err.res.data);
          console.log(err.res.status);
          console.log(err.res.headers);
        });
    };
    loadDisps();
  }, []);
  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-center flex-wrap w-screen mt-4">
        {disps.length > 0
          ? disps.map((dis) => {
              return (
                <div className="mr-4" key={dis.disp_eng}>
                  <Link to={dis.disp_eng}>{dis.disp_name}</Link>
                </div>
              );
            })
          : 'Loading'}
      </div>
    </div>
  );
};

export default ListDisps;
