import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const Header = ({ pages }) => {
  const actualLocation = '/' + useLocation().pathname.slice(1).split('/')[1];
  let engLocation = '/stats';
  if (actualLocation !== '/' && actualLocation !== '') {
    engLocation = actualLocation;
  }

  let displayedLocation = pages.find((page) => page.path == engLocation);

  return (
    <header className="pl-7 pr-4 bg-header flex items-center justify-between drop-shadow-lg">
      <div>
        <h1 className="text-2xl text-white py-7 ">{displayedLocation.name}</h1>
      </div>
      <div className="flex">
        {/* <div className="relative">
          <FontAwesomeIcon icon={faBell} className="mx-4 w-5 h-5 text-white" />
          <div className="w-2 h-2 bg-red-700 rounded-full absolute bottom-1 left-7"></div>
        </div> */}
        <FontAwesomeIcon icon={faGear} className="mx-4 w-5 h-5 text-white" />
      </div>
    </header>
  );
};

export default Header;
