import StatsPage from './pages/StatsPage';
import CallsPage from './pages/CallsPage';
import DbPage from './pages/DbPage';
import SchedulePage from './pages/SchedulePage';
import KnowPage from './pages/KnowPage';
import DbLayout from './pages/DbLayout';
import Disp from './pages/Disp';
import Street from './pages/Street';
import House from './pages/House';
import Gadget from './pages/Gadget';
import Header from './Header';
import { Routes, Route } from 'react-router-dom';

const Content = ({ pages }) => {
  return (
    <div className="h-full">
      <Header pages={pages} />
      <Routes>
        <Route path="/app/" element={<StatsPage />} />
        <Route path="/app/stats" element={<StatsPage />} />
        <Route path="/app/calls" element={<CallsPage />} />
        <Route path="/app/db" element={<DbLayout />}>
          <Route index element={<DbPage />} />
          <Route path="/app/:disp" element={<Disp />} />
          <Route path="/app/:disp/:street" element={<Street />} />
          <Route path="/app/:disp/:street/:house" element={<House />} />
          <Route path="/app/:disp/:street/:house/:gadget" element={<Gadget />} />
        </Route>
        <Route path="/app/schedule" element={<SchedulePage />} />
        <Route path="/app/know" element={<KnowPage />} />
      </Routes>
    </div>
  );
};

export default Content;
