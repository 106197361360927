import { Outlet } from 'react-router-dom';
import { useState } from 'react';

import ListDisps from './ListDisps';

const DbPage = () => {
  const [currentDisp, setCurrentDisp] = useState();

  return (
    <div className="flex flex-col h-full">
      <ListDisps />
      <Outlet />
    </div>
  );
};

export default DbPage;
