import DateAndTime from './DateAndTime';
import MenuItems from './MenuItems';
import Logo from '../img/logo-footer-bw.png';
import Foto from '../img/max.jpg';

const SideMenu = ({ pages, currentPage, setCurrentPage, user }) => {
  return (
    <div className="side-menu flex fixed bottom-0 w-screen flex-row justify-between bg-white drop-shadow-lg z-10 ">
      {/* <img src={Logo} className="mt-5 mx-5" alt="logo"></img>
      <div className="user flex flex-col">
        <div className="flex">
          <span className="opacity-0">----------------</span>
          <img src={Foto} className="w-24 h-24 rounded-full drop-shadow" alt="Max"></img>
          <span className="opacity-0">----------------</span>
        </div>
        <div className="flex flex-col text-center mx-3 mt-5">
          <h1 className="text-lg">
            {user.name} {user.familyName}
          </h1>
          <h2 className="text-xs text-my-text">{user.role}</h2>
        </div>
      </div> */}
      <MenuItems pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {/* <DateAndTime /> */}
    </div>
  );
};

export default SideMenu;
