import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const MenuItems = ({ pages, setCurrentPage }) => {
  const activeLink = 'my-4 text-my-active-text text-center';
  const normalLink = 'my-4 text-center';

  let location = useLocation();
  const currentPage = '/' + location.pathname.slice(1).split('/')[1];
  return (
    <ul className="text-my-text text-sm mx-2 flex justify-around w-screen">
      {pages.map((i) => {
        return (
          <li key={i.id} className={i.path === currentPage ? activeLink : normalLink}>
            <Link
              to={'/app' + i.path}
              onClick={() => {
                setCurrentPage({ name: i.name, id: i.id, path: i.path, isActive: true });
              }}>
              <FontAwesomeIcon icon={i.icon} className="text-lg" />
              <br></br>
              <span className="text-xs">{i.name}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MenuItems;
