import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import klshImg from '../../img/klsh.png';
import lbProImg from '../../img/lbpro.png';

const House = () => {
  let location = useLocation();
  const path = location.pathname.slice(1).split('/');
  console.log(location.pathname);
  const [loading, setLoading] = useState(false);
  const [gadgets, setGadgets] = useState([]);

  const apiUrl = 'http://45.11.26.17:5000/' + path[1] + '/' + path[2] + '/' + path[3];

  useEffect(() => {
    const loadGadgets = () => {
      axios
        .get(apiUrl)
        .then((res) => {
          setLoading(true);
          setGadgets(res.data);
          setLoading(false);
        })
        .catch((err) => {
          // Not in the 200 response range
          console.log(err.res.data);
          console.log(err.res.status);
          console.log(err.res.headers);
        });
    };
    loadGadgets();
  }, [apiUrl]);
  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex justify-center flex-wrap w-screen mt-4">
          <Link to={'/db/' + path[1] + '/' + path[2]} className="mr-3 font-bold">
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;Назад
          </Link>
          {loading
            ? 'Loading'
            : gadgets.map((g) => {
                let id = '' + g.id;
                return (
                  <div className="mx-4">
                    {g.klsh === 1 ? (
                      <div className="flex flex-col items-center">
                        <Link to={id}>
                          <img src={klshImg} alt="КЛШ" className="h-8"></img>
                        </Link>
                        <span>подъезд {g.podezd}</span>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center">
                        <Link to={id}>
                          <img src={lbProImg} alt="ЛБ Про" className="h-12"></img>
                        </Link>
                        <span>подъезд {g.podezd}</span>
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default House;
