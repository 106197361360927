import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SideMenu from './modules/SideMenu';
import Header from './modules/Header';
import Content from './modules/Content';

import './App.css';

import { faChartLine, faBell, faCity, faCalendar, faBook } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [currentPage, setCurrentPage] = useState({
    name: 'Статистика',
    id: 0,
    path: '/stats',
    icon: faChartLine,
    isActive: true,
  });

  const pages = [
    { name: 'Статистика', path: '/stats', id: 0, icon: faChartLine, isActive: false },
    { name: 'Заявки', path: '/calls', id: 1, icon: faBell, isActive: false },
    { name: 'Данные', path: '/db', id: 2, icon: faCity, isActive: false },
    { name: 'График', path: '/schedule', id: 3, icon: faCalendar, isActive: false },
    { name: 'Знания', path: '/know', id: 4, icon: faBook, isActive: false },
  ];

  const [user, setUser] = useState({
    name: 'Максим',
    familyName: 'Власьев',
    role: 'Связист',
  });

  return (
    <div className="App bg-my-bg h-screen w-screen flex">
      <SideMenu
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        user={user}
      />
      <div className="w-screen">
        <Content className="relative h-full w-screen" pages={pages} />
      </div>
    </div>
  );
}

export default App;
