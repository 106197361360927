import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Street = () => {
  let location = useLocation();

  const path = location.pathname.slice(1).split('/');

  const [loading, setLoading] = useState(false);
  const [houses, setHouses] = useState([]);

  const apiUrl = 'http://45.11.26.17:5000/' + path[1] + '/' + path[2];

  useEffect(() => {
    const loadHouses = () => {
      axios
        .get(apiUrl)
        .then((res) => {
          setLoading(true);
          setHouses(res.data);
          setLoading(false);
        })
        .catch((err) => {
          // Not in the 200 response range
          console.log(err.res.data);
          console.log(err.res.status);
          console.log(err.res.headers);
        });
    };
    loadHouses();
  }, [apiUrl]);

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex justify-center flex-wrap w-screen mt-4">
          <Link to={'/db/' + path[1]} className="mr-3 font-bold">
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;Назад
          </Link>
          {loading
            ? 'Loading'
            : houses.map((h) => {
                return (
                  <div className="mr-4">
                    <Link to={h.street_house} key={h.house_id}>
                      {h.street_house}
                    </Link>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default Street;
